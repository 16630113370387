import { createLogic } from "redux-logic";
import { ApiHelper } from "../helper/ApiHelper";
import { ApiRoutes, AppRoutes } from "../config";
import { toast } from "react-toastify";
import {
  HelpFaqActionType,
  helpFaqSuccess,
  helpFaqFailed,
} from "../action/Help_Faq";
import { showLoader } from "../action/index";
import { hideLoader } from "../action/index";

let toastId: any = null;

/**
|--------------------------------------------------
| Quiz
|--------------------------------------------------
*/

const helpFaqLogic = createLogic({
  type: HelpFaqActionType.HELP_FAQ_REQUEST,
  async process(data, dispatch: any, done) {
    const action: any = data.action;
    // dispatch(showLoader());
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.HELP_FAQ_DATA.service,
      ApiRoutes.HELP_FAQ_DATA.url,
      ApiRoutes.HELP_FAQ_DATA.method,
      ApiRoutes.HELP_FAQ_DATA.authenticate,
      undefined,
      undefined
    );
    if (response && !response.isError) {
      const { data } = response.data;
      dispatch(
        helpFaqSuccess({
          helpfaqData: data,
        })
      );
      done();
    } else {
      dispatch(
        helpFaqFailed({
          error: response.messages[0],
          isError: true,
        })
      );
      done();
    }
  },
});

export const HelpFaqLogic = [helpFaqLogic];
