import { IVerifiedTokenModel } from '../interfaces/VerifiedToken';

export const VerifiedTokenInitialState: IVerifiedTokenModel = {
  isLoading: true,
  verifiedToken: {
    // organizationName: '',
    // wildCardDomain: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    courseId: [],
  },
  isError: false,
};
