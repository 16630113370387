import { createLogic } from 'redux-logic';
import { toast } from 'react-toastify';
import {
  ChangePasswordActionTypes,
  showLoader,
  hideLoader,
  changePasswordSuccess,
  changePasswordFailed,
} from '../action';
import { ApiRoutes } from '../config';
import { ApiHelper } from '../helper/ApiHelper';

let toastId: any = null;

const ChangePasswordLogic = createLogic({
  type: ChangePasswordActionTypes.CHANGE_PASSWORD_REQUEST,
  async process(data, dispatch: any, done) {
    const action: any = data.action;
    toast.dismiss();
    dispatch(showLoader());
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.USER_CHANGE_PASSWORD.service,
      ApiRoutes.USER_CHANGE_PASSWORD.url,
      ApiRoutes.USER_CHANGE_PASSWORD.method,
      ApiRoutes.USER_CHANGE_PASSWORD.authenticate,
      undefined,
      action.payload,
    );
    if (response && !response.isError) {
      dispatch(hideLoader());
      dispatch(changePasswordSuccess());
      if (!toast.isActive(toastId)) {
        toastId = toast.success(response.messages[0]);
      }
      done();
    } else {
      dispatch(hideLoader());
      if (!toast.isActive(toastId)) {
        toastId = toast.error(response.messages[0]);
      }
      dispatch(
        changePasswordFailed({
          isError: true,
        }),
      );
      done();
    }
  },
});

export const ChangePasswordLogics = [ChangePasswordLogic];
