import { handleActions } from "redux-actions";
import { ConfigActionTypes } from "../action";
import { ConfigInitialStates } from "../states";
import { IConfigModal } from "../interfaces/configuration.interface";

export const configReducer = handleActions<IConfigModal, any>(
  {
    [ConfigActionTypes.CONFIGURATION_INFO_REQUEST]: (
      state = ConfigInitialStates,
      action
    ): IConfigModal => ({
      ...state,
      isLoading: true,
      configInfo: {},
    }),
    [ConfigActionTypes.CONFIGURATION_INFO_SUCCESS]: (
      state = ConfigInitialStates,
      action
    ): IConfigModal => ({
      ...state,
      isLoading: false,
      configInfo: action.payload.configInfo,
    }),
    [ConfigActionTypes.CONFIGURATION_INFO_FAILURE]: (
      state = ConfigInitialStates,
      action
    ): IConfigModal => ({
      ...state,
      isLoading: false,
      isError: true,
    }),
  },
  ConfigInitialStates
);
