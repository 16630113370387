import { createAction } from "redux-actions";

export enum ResourcesActionTypes {
  RESOURCES_FETCH_REQUEST = "Resources  Fetch Request",
  RESOURCES_FETCH_SUCCESS = "Resources  Fetch Request successfully",
  RESOURCES_FETCH_FAILURE = "Resources  Fetch Request Failed!",
}

// Fetch course details

export const resourceFetchRequest = createAction(
  ResourcesActionTypes.RESOURCES_FETCH_REQUEST
);
export const resourceFetchSuccess = createAction(
  ResourcesActionTypes.RESOURCES_FETCH_SUCCESS
);
export const resourceFetchFailed = createAction(
  ResourcesActionTypes.RESOURCES_FETCH_FAILURE
);
