import { IVideoDetailModal, ISelectedVideoModal, IPausedVideoModal, ISubmitRatingModal } from "../interfaces";

export const VideoDetailInitialStates: IVideoDetailModal = {
  videoDetailsData: {},
  isError: false,
  isLoading: false
};

export const SelectedVideoInitialStates: ISelectedVideoModal = {
  videoData: {},
  isError: false,
  isLoading: false
};

export const PausedVideoInitialStates: IPausedVideoModal = {
  videoData: {},
  isError: false,
  isLoading: false
};

export const SubmitRatingInitialStates: ISubmitRatingModal = {
  videoData: {},
  isError: false,
  isLoading: false
};

export const UpdateVideoProgressInitialStates: ISelectedVideoModal = {
  videoData: {},
  isError: false,
  isLoading: false
};

