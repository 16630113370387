import { createAction } from "redux-actions";

export * from "./Auth";
export * from "./Profile";
export * from "./ChangePassword";
export * from "./Video";
export * from "./HomePage";
export * from "./VerifiedToken";
export * from "./Quiz";
export * from "./Review-Quiz";
export * from "./Module";
export * from "./Help_Faq";
export * from "./CheckIn";
export * from "./QuizHistory";
export * from "./Resources";
export * from "./Configuration";
//
export const redirectTo = createAction("REDIRET_TO");
//
export const showLoader = createAction("SHOW_LOADER");
export const hideLoader = createAction("HIDE_LOADER");
