import { IQuizHistoryModal, IQuizAvgScoreModal } from "../interfaces/quizhistory.interface";

export const QuizHistoryInitialStates: IQuizHistoryModal = {
  quizHistoryData: {},
  isError: false,
  isLoading: false
};
export const QuizScoreInitialStates: IQuizAvgScoreModal = {
  quizScoreData: 0,
  isError: false,
  isLoading: false
};
