import { combineReducers, AnyAction, Reducer } from "redux";
import { handleActions } from "redux-actions";
import { IRootState } from "../interfaces";
// import { reducer as _Form } from "redux-form";

import {
  loginReducer,
  forgotPasswordReducer,
  linkVerificationReducer,
  resetPasswordReducer,
} from "./Auth";
import { profileReducer } from "./Profile";
import { changePasswordReducer } from "./ChangePassword";
import {
  videoDetailReducer,
  SelectedVideoReducer,
  PausedVideoReducer,
  SubmitRatingReducer,
} from "./Video";

import { homePageReducer } from "./HomePage";

import { verifiedTokenReducer } from "./VerifiedToken";
import { quizReducer } from "./Quiz";
import { quizHistoryReducer, avgQuizScoreReducer } from "./QuizHistory";
import { helpFaqReducer } from "./Help_Faq";
import { checkInReducer, attendanceReducer} from "./CheckIn";
import { motivationReducer} from "./Motivation";
import { topVideosReducer} from "./TopVideos";

import { reviewQuizReducer } from "./Review-Quiz";
import {
  moduleDetailReducer,
  SelectedModuleReducer,
  UpdateModuleProgressReducer,
  moduleCountReducer,
  moduleUserCountReducer
} from "./Module";
import { configReducer } from "./Configuration";

//import { reducer as idle } from "../states/components/redux-idle-monitor";
// import { reducer as formReducer } from "redux-form";

export const mainReducer = handleActions(
  {
    SHOW_LOADER: () => ({
      showLoader: true,
    }),
    HIDE_LOADER: () => ({
      showLoader: false,
    }),
  },
  {
    showLoader: false,
  }
);

export const RootReducer: Reducer<IRootState, AnyAction> =
  combineReducers<IRootState>({
    mainReducer: mainReducer as any,
    loginReducer: loginReducer as any,
    forgotPasswordReducer: forgotPasswordReducer as any,
    linkVerificationReducer: linkVerificationReducer as any,
    resetPasswordReducer: resetPasswordReducer as any,
    profileReducer: profileReducer as any,
    changePasswordReducer: changePasswordReducer as any,
    videoDetailReducer: videoDetailReducer as any,
    SelectedVideoReducer: SelectedVideoReducer as any,
    PausedVideoReducer: PausedVideoReducer as any,
    SubmitRatingReducer: SubmitRatingReducer as any,
    homePageReducer: homePageReducer as any,
    verifiedTokenReducer: verifiedTokenReducer as any,
    quizReducer: quizReducer as any,
    reviewQuizReducer: reviewQuizReducer as any,
    moduleDetailReducer: moduleDetailReducer as any,
    SelectedModuleReducer: SelectedModuleReducer as any,
    UpdateModuleProgressReducer: UpdateModuleProgressReducer as any,
    helpFaqReducer: helpFaqReducer as any,
    checkInReducer: checkInReducer as any,
    motivationReducer: motivationReducer as any,
    topVideosReducer: topVideosReducer as any,
    moduleCountReducer: moduleCountReducer as any,
    moduleUserCountReducer: moduleUserCountReducer as any,
    quizHistoryReducer: quizHistoryReducer as any,
    SelectedDateCheckInReducer: SelectedModuleReducer as any,
    configReducer : configReducer as any,
    attendanceReducer: attendanceReducer as any,
    avgQuizScoreReducer: avgQuizScoreReducer as any,
    // idle,
  });
