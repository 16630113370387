import { createLogic } from 'redux-logic';
import { ApiHelper } from '../helper/ApiHelper';
import { ApiRoutes, AppRoutes } from '../config';
import { toast } from 'react-toastify';
import { VerifiedActionType, verifiedTokenSuccess, verifiedTokenFailed, redirectTo, showLoader } from '../action';
import { hideLoader } from '../action/index';

let toastId: any = null;

/**
|--------------------------------------------------
| Verified Token
|--------------------------------------------------
*/

const verifiedTokenLogic = createLogic({

  type: VerifiedActionType.VERIFIED_TOKEN_REQUEST,
  async process(data, dispatch: any, done) {
    const action: any = data.action
    dispatch(showLoader());
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_PROFILE_INFO.service,
      ApiRoutes.GET_PROFILE_INFO.url,
      ApiRoutes.GET_PROFILE_INFO.method,
      ApiRoutes.GET_PROFILE_INFO.authenticate,
      action.payload,
      undefined,
    );
    if (response && !response.isError) {
      const { data } = response.data;
      dispatch(
        verifiedTokenSuccess({
          verifiedToken: data,
        }),
      );
      dispatch(redirectTo({ path: AppRoutes.MY_ACCOUNT }));
      dispatch(hideLoader());
      done();
    } else {
      if (!toast.isActive(toastId)) {
        toastId = toast.error(response.messages[0]);
      }
      localStorage.removeItem('usertoken');
      localStorage.removeItem('firstName');
      localStorage.removeItem('viewAddedIds');
      dispatch(
        verifiedTokenFailed({
          error: response.messages[0],
          isError: true,
        }),
      );
      dispatch(redirectTo({ path: AppRoutes.HOME }));
      done();
    }
  },
});

export const VerificationLogic = [
  verifiedTokenLogic
]

