import { IModuleCountModal, IModuleDetailModal, IModuleUserCountModal, ISelectedModuleModal, IUpdateModuleProgressModal } from "../interfaces/module.interface";

export const ModuleDetailInitialStates: IModuleDetailModal = {
  moduleData: {},
  isError: false,
  isLoading: false
};

export const ModuleCountInitialStates: IModuleCountModal = {
  moduleCount: 0,
  isError: false,
  isLoading: false
};

export const ModuleUserCountInitialStates: IModuleUserCountModal = {
  moduleUserCount: {},
  isError: false,
  isLoading: false
};



export const SelectedModuleInitialStates: ISelectedModuleModal = {
  moduleData: {},
  isError: false,
  isLoading: false
};

export const UpdateModuleProgressInitialStates: IUpdateModuleProgressModal = {
  moduleData: {},
  isError: false,
  isLoading: false
};

