import { handleActions } from 'redux-actions';
import { QuizHistoryActionTypes } from '../action/QuizHistory';
import { IQuizHistoryModal, IQuizAvgScoreModal } from '../interfaces/quizhistory.interface';
import { QuizHistoryInitialStates, QuizScoreInitialStates } from '../states/QuizHistory';

export const quizHistoryReducer = handleActions<IQuizHistoryModal, any>(
  {
    [QuizHistoryActionTypes.QUIZ_HISTORY_REQUEST]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [QuizHistoryActionTypes.QUIZ_HISTORY_SUCCESS]: (state, action) => ({
      ...state,
      quizHistoryData: action.payload.quizHistoryData,
      isError: false,
      isLoading: false,
    }),
    [QuizHistoryActionTypes.QUIZ_HISTORY_FAILURE]: (state, action) => ({
      ...state,
      isLoading: false,
      isError: true,
    }),
  },
  QuizHistoryInitialStates,
);

export const avgQuizScoreReducer = handleActions<IQuizAvgScoreModal, any>(
  {
    [QuizHistoryActionTypes.GET_AVERAGE_SCORE_REQUEST]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [QuizHistoryActionTypes.GET_AVERAGE_SCORE_SUCCESS]: (state, action) => ({
      ...state,
      quizScoreData: action.payload.data,
      isError: false,
      isLoading: false,
    }),
    [QuizHistoryActionTypes.GET_AVERAGE_SCORE_FAILURE]: (state, action) => ({
      ...state,
      isLoading: false,
      isError: true,
    }),
  },
  QuizScoreInitialStates,
);