import { handleActions } from "redux-actions";
import { ProfileActionTypes } from "../action";
import { ProfileInitialStates } from "../states";
import { IProfileModel } from "../interfaces/profile.interface";

export const profileReducer = handleActions<IProfileModel, any>(
  {
    [ProfileActionTypes.PROFILE_INFO_REQUEST]: (
      state = ProfileInitialStates,
      action
    ): IProfileModel => ({
      ...state,
      isLoading: true,
      profileInfo: {},
    }),
    [ProfileActionTypes.PROFILE_INFO_SUCCESS]: (
      state = ProfileInitialStates,
      action
    ): IProfileModel => ({
      ...state,
      isLoading: false,
      profileInfo: action.payload.profileInfo,
    }),
    [ProfileActionTypes.PROFILE_INFO_FAILURE]: (
      state = ProfileInitialStates,
      action
    ): IProfileModel => ({
      ...state,
      isLoading: false,
      isError: true,
    }),
    [ProfileActionTypes.UPDATE_PROFILE_INFO_REQUEST]: (
      state = ProfileInitialStates,
      action
    ): IProfileModel => ({
      ...state,
      profileInfo: {},
      isLoading: true,
    }),
    [ProfileActionTypes.UPDATE_PROFILE_INFO_SUCCESS]: (
      state = ProfileInitialStates,
      action
    ): IProfileModel => ({
      ...state,
      isLoading: false,
      profileInfo: action.payload.profileInfo,
    }),
    [ProfileActionTypes.UPDATE_PROFILE_INFO_FAILURE]: (
      state = ProfileInitialStates,
      action
    ): IProfileModel => ({
      ...state,
      isLoading: false,
      isError: true,
    }),
    [ProfileActionTypes.UPDATE_IMAGE_REQUEST]: (
      state = ProfileInitialStates,
      action
    ): IProfileModel => ({
      ...state,
      profileInfo: {},
      isLoading: true,
    }),

    [ProfileActionTypes.DELETE_IMAGE_REQUEST]: (
      state = ProfileInitialStates,
      action
    ): IProfileModel => ({
      ...state,
      profileInfo: {},
      isLoading: true,
    }),
  },
  ProfileInitialStates
);
