import { handleActions } from "redux-actions";
import { CheckInActionTypes } from "../action";
import { TopVideosModal } from "../interfaces";
import { TopVideosInitialStates } from "../states";

export const topVideosReducer = handleActions<TopVideosModal, any>(
  {
    [CheckInActionTypes.GET_TOP_VIDEOS_REQUEST]:
      (state = TopVideosInitialStates, action
      ): TopVideosModal => ({
      ...state,
      isLoading: true,
    }),
    [CheckInActionTypes.GET_TOP_VIDEOS_SUCCESS]: (state=TopVideosInitialStates, action) :TopVideosModal=> ({
      ...state,
      displayVideos: action.payload.displayVideos,
      isError: false,
      isLoading: false,
    }),
    [CheckInActionTypes.GET_TOP_VIDEOS_FAILURE]: (state=TopVideosInitialStates, action):TopVideosModal => ({
      ...state,
      isLoading: false,
      isError: true,
    }),
  },
  TopVideosInitialStates,
);



