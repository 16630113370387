import { createAction } from 'redux-actions';

export enum VideoActionTypes {

  VIDEO_DETAIL_REQUEST = 'Request for video detail',
  VIDEO_DETAIL_SUCCESS = 'Fetch video details successfully',
  VIDEO_DETAIL_FAILURE = 'Failed to load video detail',

  SELECTED_VIDEO_DETAIL_REQUEST = 'Request for get selected video',
  SELECTED_VIDEO_DETAIL_SUCCESS = 'Fetch selected video successfully.',
  SELECTED_VIDEO_DETAIL_FAILURE = 'Failed to load selected video.',

  PAUSED_TIME_REQUEST = 'Request for storing video paused time',
  PAUSED_TIME_SUCCESS = 'Fetch results successfully.',
  PAUSED_TIME_FAILURE = 'Failed to store last paused timed',

  SUBMIT_RATING_REQUEST = 'Request for rating submission',
  SUBMIT_RATING_SUCCESS = 'Fetch results successfully.',
  SUBMIT_RATING_FAILURE = 'Failed to rating for the video',

}



// Fetch Video details
export const videoDetailRequest = createAction(
  VideoActionTypes.VIDEO_DETAIL_REQUEST,
);
export const videoDetailSuccess = createAction(
  VideoActionTypes.VIDEO_DETAIL_SUCCESS,
);
export const videoDetailFailed = createAction(
  VideoActionTypes.VIDEO_DETAIL_FAILURE,
);


// Fetch selected video
export const getSelectedVideoRequest = createAction(
  VideoActionTypes.SELECTED_VIDEO_DETAIL_REQUEST,
);
export const getSelectedVideoSuccess = createAction(
  VideoActionTypes.SELECTED_VIDEO_DETAIL_SUCCESS,
);
export const getSelectedVideoFailed = createAction(
  VideoActionTypes.SELECTED_VIDEO_DETAIL_FAILURE,
);
export const pausedTimeRequest = createAction(
  VideoActionTypes.PAUSED_TIME_REQUEST,
);
export const pausedTimeSuccess = createAction(
  VideoActionTypes.PAUSED_TIME_SUCCESS,
);
export const pausedTimeFailed = createAction(
  VideoActionTypes.PAUSED_TIME_FAILURE,
);
export const submitRatingRequest = createAction(
  VideoActionTypes.SUBMIT_RATING_REQUEST,
);
export const submitRatingSuccess = createAction(
  VideoActionTypes.SUBMIT_RATING_SUCCESS,
);
export const submitRatingFailed = createAction(
  VideoActionTypes.SUBMIT_RATING_FAILURE,
);

