import { handleActions } from 'redux-actions';
import { ModuleActionTypes } from '../action/Module';
import {
  IModuleDetailModal
  , ISelectedModuleModal,
  IUpdateModuleProgressModal,
  IModuleCountModal,
  IModuleUserCountModal,
} from '../interfaces/module.interface';
import {
  ModuleDetailInitialStates,
  ModuleCountInitialStates,
  ModuleUserCountInitialStates,
  SelectedModuleInitialStates,
  UpdateModuleProgressInitialStates
} from '../states/Module';

export const moduleDetailReducer = handleActions<IModuleDetailModal, any>(
  {
    [ModuleActionTypes.MODULE_DETAIL_REQUEST]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [ModuleActionTypes.MODULE_DETAIL_SUCCESS]: (state, action) => ({
      ...state,
      moduleData: action.payload.moduleData,
      isError: false,
      isLoading: false,
    }),
    [ModuleActionTypes.MODULE_DETAIL_FAILURE]: (state, action) => ({
      ...state,
      isLoading: false,
      isError: true,
    }),
  },
  ModuleDetailInitialStates,
);

export const moduleCountReducer = handleActions<IModuleCountModal, any>(
  {
    [ModuleActionTypes.GET_KPI_MODULE_REQUEST]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [ModuleActionTypes.GET_KPI_MODULE_SUCCESS]: (state, action) => ({
      ...state,
      moduleCount: action.payload.data,
      isError: false,
      isLoading: false,
    }),
    [ModuleActionTypes.GET_KPI_MODULE_FAILURE]: (state, action) => ({
      ...state,
      isLoading: false,
      isError: true,
    }),
  },
  ModuleCountInitialStates,
);

export const moduleUserCountReducer = handleActions<IModuleUserCountModal, any>(
  {
    [ModuleActionTypes.GET_KPI_USER_MODULE_REQUEST]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [ModuleActionTypes.GET_KPI_USER_MODULE_SUCCESS]: (state, action) => ({
      ...state,
      moduleUserCount: action.payload.data[0],
      isError: false,
      isLoading: false,
    }),
    [ModuleActionTypes.GET_KPI_USER_MODULE_FAILURE]: (state, action) => ({
      ...state,
      isLoading: false,
      isError: true,
    }),
  },
  ModuleUserCountInitialStates,
);



export const SelectedModuleReducer = handleActions<ISelectedModuleModal, any>(
  {
    [ModuleActionTypes.SELECTED_MODULE_DETAIL_REQUEST]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [ModuleActionTypes.SELECTED_MODULE_DETAIL_SUCCESS]: (state, action) => ({
      ...state,
      moduleData: action.payload,
      isError: false,
      isLoading: false,
    }),
    [ModuleActionTypes.SELECTED_MODULE_DETAIL_FAILURE]: (state, action) => ({
      ...state,
      isLoading: false,
      isError: true,
    }),
  },
  SelectedModuleInitialStates,
);



export const UpdateModuleProgressReducer = handleActions<IUpdateModuleProgressModal, any>(
  {
    [ModuleActionTypes.SELECTED_MODULE_PROGRESS_UPDATE_REQUEST]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [ModuleActionTypes.SELECTED_MODULE_PROGRESS_UPDATE_SUCCESS]: (state, action) => ({
      ...state,
      moduleData: action.payload,
      isError: false,
      isLoading: false,
    }),
    [ModuleActionTypes.SELECTED_MODULE_PROGRESS_UPDATE_FAILURE]: (state, action) => ({
      ...state,
      isLoading: false,
      isError: true,
    }),
  },
  UpdateModuleProgressInitialStates,
);
