import { handleActions } from 'redux-actions';
import { IQuizModel } from '../interfaces/Quiz';
import { QuizActionType } from '../action/Quiz';
import { QuizInitialState } from '../states/Quiz';

export const quizReducer = handleActions<IQuizModel, any>(
  {
    [QuizActionType.QUIZ_REQUEST]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [QuizActionType.QUIZ_SUCCESS]: (
      state = QuizInitialState,
      action,
    ): IQuizModel => ({
      ...state,
      isLoading: false,
      quizData: action.payload.quizData,
    }),
    [QuizActionType.QUIZ_FAILED]: (state, action) => ({
      ...state,
      isLoading: false,
      isError: true,
    }),
    [QuizActionType.QUIZ_SUBMIT_REQUEST]: (
      state = QuizInitialState,
      action,
    ): IQuizModel => ({
      ...state,
      isLoading: false,
      quizData: action.payload.quizData
    }),
    [QuizActionType.QUIZ_SUBMIT_SUCCESS]: (
      state = QuizInitialState,
      action,
    ): IQuizModel => ({
      ...state,
      isLoading: false,
      quizData: action.payload.quizData,
      quizSummaryData: action.payload.quizSummaryData,
    }),
    [QuizActionType.QUIZ_SUBMIT_FAILED]: (
      state = QuizInitialState,
      action,
    ): IQuizModel => ({
      ...state,
      isLoading: false,
      isError: true,
    })
  },
  QuizInitialState,
)