import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './assets/css/style.scss';
import ReactGA from 'react-ga';
if(window.location.href.indexOf("lo-kem") != -1) {
    ReactGA.initialize('UA-168604280-1');
    console.log("lokem stats changes "+window.location.href);
    ReactGA.pageview(window.location.pathname + window.location.search);
}
ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
