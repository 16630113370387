import { IDLESTATUS_AWAY, IDLESTATUS_INACTIVE, IDLESTATUS_EXPIRED } from './constants'
 
//...
 const logout =()=>{
alert("logging out ")
 }
export const idleStatusDelay = idleStatus => (dispatch, getState) => {
  if(idleStatus === IDLESTATUS_AWAY)
    return 20000 // User becomes away after 20 seconds inactivity
  if(idleStatus === IDLESTATUS_INACTIVE)
    return 50000 // Call database to look up the users delay time
  if(idleStatus === IDLESTATUS_EXPIRED)
    return 60000 // Log them out after another minute after they enter the inactive status
}
 
export const activeStatusAction = (dispatch, getState) => alert('welcome back!')
 
export const idleStatusAction = idleStatus => (dispatch, getState) => {
  if(idleStatus === IDLESTATUS_AWAY)    
    console.info('user is away...')
  if(idleStatus === IDLESTATUS_INACTIVE)
    alert('You still there or what??')
  if(idleStatus === IDLESTATUS_EXPIRED)
    dispatch(logout())
}