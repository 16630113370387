import { createLogic } from 'redux-logic';
import { toast } from 'react-toastify';
import {
  AuthActionTypes,
  LoginSuccess,
  LoginFailed,
  redirectTo,
  showLoader,
  hideLoader,
  ForgotPasswordSuccess,
  ForgotPasswordFailure,
  LinkVerificationSuccess,
  LinkVerificationFailure,
  ResetPasswordSuccess,
  ResetPasswordFailure,
} from '../action';
import { ApiRoutes, AppRoutes } from '../config';
import { ApiHelper } from '../helper/ApiHelper';
/**
 *
 */

let toastId: any = null;

const loginLogic = createLogic({
  type: AuthActionTypes.LOGIN_REQUEST,
  async process(data, dispatch: any, done) {
    const action: any = data.action;
    dispatch(showLoader());
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.USER_LOGIN.service,
      ApiRoutes.USER_LOGIN.url,
      ApiRoutes.USER_LOGIN.method,
      ApiRoutes.USER_LOGIN.authenticate,
      undefined,
      action.payload,
    );
    dispatch(hideLoader());
    if (response && !response.isError) { 
      const { data, token } = response.data;
      const { email, password, rememberMe } = action.payload;
      localStorage.setItem('userToken', token);
      localStorage.setItem('firstName', data.firstName);

      // if (!toast.isActive(toastId)) {
      //   toastId = toast.success(response.messages[0]);
      // }
      if (rememberMe) {
        localStorage.setItem('email', email);
        localStorage.setItem('password', password);
      }
      localStorage.setItem('rememberMe', rememberMe);
      dispatch(
        LoginSuccess({
          userData: data,
        }),
      );
      dispatch(redirectTo({ path: AppRoutes.WELCOME }));
      done();
    } else {
      dispatch(hideLoader());
      if (!toast.isActive(toastId)) {
        toastId = toast.error(response.messages[0]);
      }
      dispatch(        
        LoginFailed({
          error: response.messages[0],
          attempts: response.data.attempts
        }),
      );
      done();
    }
  },
});

const ForgotPasswordLogic = createLogic({
  type: AuthActionTypes.FORGOT_PASSWORD_REQUEST,
  async process(data, dispatch: any, done) {
    const action: any = data.action;
    dispatch(showLoader());
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.FORGOT_PASSWORD.service,
      ApiRoutes.FORGOT_PASSWORD.url,
      ApiRoutes.FORGOT_PASSWORD.method,
      ApiRoutes.FORGOT_PASSWORD.authenticate,
      undefined,
      action.payload,
    );
    if (response && !response.isError) {
      dispatch(hideLoader());
      if (!toast.isActive(toastId)) {
        toastId = toast.success(response.messages[0]);
      }
      dispatch(
        ForgotPasswordSuccess({
          userData: response.data.data,
        }),
      );
      done();
    } else {
      dispatch(hideLoader());
      if (!toast.isActive(toastId)) {
        toastId = toast.error(response.messages[0]);
      }
      dispatch(
        ForgotPasswordFailure({
          error: response.messages[0],
        }),
      );
      done();
    }
  },
});

const linkVerificationLogic = createLogic({
  type: AuthActionTypes.LINK_VERIFICATION_REQUEST,
  async process(data, dispatch: any, done) {
    const action: any = data.action;
    dispatch(showLoader());
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.LINK_VERIFICATION.service,
      ApiRoutes.LINK_VERIFICATION.url,
      ApiRoutes.LINK_VERIFICATION.method,
      ApiRoutes.LINK_VERIFICATION.authenticate,
      action.payload,
      undefined,
    );
    if (response && !response.isError) {
      const { data } = response.data;
      const { verifyToken, email, id } = data;
      dispatch(hideLoader());
      dispatch(
        LinkVerificationSuccess({
          verificationData: {
            verifyToken,
            email,
            id,
          },
        }),
      );
      done();
    } else {
      dispatch(hideLoader());
      dispatch(LinkVerificationFailure());
      done();
    }
  },
});

const resetPasswordLogic = createLogic({
  type: AuthActionTypes.RESET_PASSWORD_REQUEST,
  async process(data, dispatch: any, done) {
    const action: any = data.action;
    dispatch(showLoader());
    toast.dismiss();
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.RESET_PASSWORD.service,
      ApiRoutes.RESET_PASSWORD.url,
      ApiRoutes.RESET_PASSWORD.method,
      ApiRoutes.RESET_PASSWORD.authenticate,
      undefined,
      action.payload,
    );

    if (response && !response.isError) {

      dispatch(hideLoader());
      if (!toast.isActive(toastId)) {
        toastId = toast.success(response.messages[0]);
      }
      dispatch(ResetPasswordSuccess());
      done();
    } else {
      dispatch(hideLoader());
      dispatch(ResetPasswordFailure());
      done();
    }
  },
});

const logOutLogic = createLogic({
  type: AuthActionTypes.LOGOUT_REQUEST,
  async process({ action }, dispatch: any, done) {
    localStorage.removeItem('userToken');
    localStorage.removeItem('firstName');
    localStorage.removeItem('viewAddedIds');
    dispatch(redirectTo({ path: AppRoutes.HOME }));
    done();
  },
});

export const LoginLogics = [
  loginLogic,
  logOutLogic,
  ForgotPasswordLogic,
  linkVerificationLogic,
  resetPasswordLogic,
];
