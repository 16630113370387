import { createLogic } from "redux-logic";
import { toast } from "react-toastify";
import { ApiRoutes } from "../config";
import { ApiHelper } from "../helper/ApiHelper";
import {
  ProfileActionTypes,
  getProfileSuccess,
  getProfileFailed,
  updateProfileSuccess,
  updateProfileFailed,
  getProfileRequest,
  redirectTo,
  deleteImageRequest,
} from "../action";
import { IRootState } from "../interfaces";

let toastId: any = null;
// create logic here
const profileInfoLogic = createLogic({
  type: ProfileActionTypes.PROFILE_INFO_REQUEST,
  async process(data, dispatch: any, done) {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_PROFILE_INFO.service,
      ApiRoutes.GET_PROFILE_INFO.url,
      ApiRoutes.GET_PROFILE_INFO.method,
      ApiRoutes.GET_PROFILE_INFO.authenticate,
      undefined,
      undefined
    );
    if (response && !response.isError && response.data) {
      const { data, courseId } = response.data;
      // const {
      //   firstName,
      //   email,
      //   interest,
      //   occupation,
      //   educationLevel,
      //   experienceLevel,
      //   // courseId,
      // } = data;
      // To grant access to the website according to account information
      let profileCompleted = true;
      // if (
      //   firstName &&
      //   email &&
      //   interest &&
      //   interest.length &&
      //   occupation &&
      //   educationLevel &&
      //   experienceLevel
      // ) {
      // profileCompleted = true;
      // }
      // data.courseId = courseId;
      // if (courseId && courseId.length) {
      //   data.notStartedCourses = courseId.filter(
      //     (course: any) => !course.status,
      //   );
      //   data.runningCourses = courseId.filter(
      //     (course: any) => course.status === 'Started',
      //   );
      //   data.completedCourses = courseId.filter(
      //     (course: any) => course.status === 'Completed',
      //   );
      // }
      dispatch(
        getProfileSuccess({
          profileInfo: { ...data, profileCompleted },
        })
      );
      done();
    } else {
      toast.dismiss();
      if (!toast.isActive(toastId)) {
        toastId = toast.error(response.messages[0]);
      }
      localStorage.removeItem("userToken");
      localStorage.removeItem("firstName");
      localStorage.removeItem("viewAddedIds");
      dispatch(
        getProfileFailed({
          error: response.messages[0],
          isError: true,
        })
      );
      dispatch(redirectTo({ path: "/" }));
      done();
    }
  },
});

const updateProfileLogic = createLogic({
  type: ProfileActionTypes.UPDATE_PROFILE_INFO_REQUEST,
  async process(data, dispatch: any, done) {
    const action: any = data.action;
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_PROFILE_INFO.service,
      ApiRoutes.UPDATE_PROFILE_INFO.url,
      ApiRoutes.UPDATE_PROFILE_INFO.method,
      ApiRoutes.UPDATE_PROFILE_INFO.authenticate,
      undefined,
      action.payload
    );
    if (response && !response.isError) {
      if (!toast.isActive(toastId) && !action.payload.courseStarted) {
        toastId = toast.success(response.messages[0]);
      }
      const state: IRootState = data.getState() as IRootState;
      dispatch(
        updateProfileSuccess({
          profileInfo: action.payload.courseStarted
            ? state.profileReducer.profileInfo
            : action.payload,
        })
      );
      dispatch(getProfileRequest());
      done();
    } else {
      if (!toast.isActive(toastId)) {
        toastId = toast.error(response.messages[0]);
      }
      dispatch(
        updateProfileFailed({
          error: response.messages[0],
        })
      );
      done();
    }
  },
});
// update Image logic
const updateImageLogic = createLogic({
  type: ProfileActionTypes.UPDATE_IMAGE_REQUEST,
  async process(data, dispatch: any, done) {
    const action: any = data.action;
    const response = await new ApiHelper().uploadImageToServer(
      ApiRoutes.UPDATE_IMAGE.service,
      ApiRoutes.UPDATE_IMAGE.url,
      ApiRoutes.UPDATE_IMAGE.method,
      ApiRoutes.UPDATE_IMAGE.authenticate,
      undefined,
      action.payload
    );
    // debugger;
    if (response && !response.isError) {
      if (!toast.isActive(toastId) && !action.payload.courseStarted) {
        toastId = toast.success(response.messages[0]);
      }
      const state: IRootState = data.getState() as IRootState;
      dispatch(
        updateProfileSuccess({
          profileInfo: action.payload.courseStarted
            ? state.profileReducer.profileInfo
            : action.payload,
        })
      );
      dispatch(getProfileRequest());
      done();
    } else {
      if (!toast.isActive(toastId)) {
        toastId = toast.error(response.messages[0]);
      }
      dispatch(
        updateProfileFailed({
          error: response.messages[0],
        })
      );
      done();
    }
  },
});

// Delete Image logic

const deleteImageLogic = createLogic({
  type: ProfileActionTypes.DELETE_IMAGE_REQUEST,
  async process(data, dispatch: any, done) {
    const action: any = data.action;
    const response = await new ApiHelper().deleteImageFromServer(
      ApiRoutes.DELETE_IMAGE.service,
      ApiRoutes.DELETE_IMAGE.url,
      ApiRoutes.DELETE_IMAGE.method,
      ApiRoutes.DELETE_IMAGE.authenticate,
      undefined,
      action.payload
    );

    console.log("response delete profile image ?>>>>>>", response);
    if (response && !response.isError) {
      if (!toast.isActive(toastId) && !action.payload.courseStarted) {
        toastId = toast.success(response.messages[0]);
      }
      const state: IRootState = data.getState() as IRootState;
      dispatch(
        updateProfileSuccess({
          profileInfo: action.payload.courseStarted
            ? state.profileReducer.profileInfo
            : action.payload,
        })
      );
      dispatch(getProfileRequest());
      done();
    } else {
      if (!toast.isActive(toastId)) {
        toastId = toast.error(response.messages[0]);
      }
      dispatch(
        updateProfileFailed({
          error: response.messages[0],
        })
      );
      done();
    }
  },
});

export const ProfileInfoLogics = [
  profileInfoLogic,
  updateProfileLogic,
  updateImageLogic,
  deleteImageLogic,
];
