import { handleActions } from 'redux-actions';
import { VideoActionTypes } from '../action/Video';
import {
  IVideoDetailModal,
  ISelectedVideoModal,
} from '../interfaces/video.interface';
import {
  VideoDetailInitialStates,
  SelectedVideoInitialStates,
  PausedVideoInitialStates,
  SubmitRatingInitialStates
} from '../states/Video';

export const videoDetailReducer = handleActions<IVideoDetailModal, any>(
  {
    [VideoActionTypes.VIDEO_DETAIL_REQUEST]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [VideoActionTypes.VIDEO_DETAIL_SUCCESS]: (state, action) => ({
      ...state,
      videoDetailsData: action.payload.videoDetailsData,
      isError: false,
      isLoading: false,
    }),
    [VideoActionTypes.VIDEO_DETAIL_FAILURE]: (state, action) => ({
      ...state,
      isLoading: false,
      isError: true,
    }),
  },
  VideoDetailInitialStates,
);



export const SelectedVideoReducer = handleActions<ISelectedVideoModal, any>(
  {
    [VideoActionTypes.SELECTED_VIDEO_DETAIL_REQUEST]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [VideoActionTypes.SELECTED_VIDEO_DETAIL_SUCCESS]: (state, action) => ({
      ...state,
      videoData: action.payload,
      isError: false,
      isLoading: false,
    }),
    [VideoActionTypes.SELECTED_VIDEO_DETAIL_FAILURE]: (state, action) => ({
      ...state,
      isLoading: false,
      isError: true,
    }),
  },
  SelectedVideoInitialStates,
);

export const PausedVideoReducer = handleActions<ISelectedVideoModal, any>(
  {
    [VideoActionTypes.PAUSED_TIME_REQUEST]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [VideoActionTypes.PAUSED_TIME_SUCCESS]: (state, action) => ({
      ...state,
      videoData: action.payload,
      isError: false,
      isLoading: false,
    }),
    [VideoActionTypes.PAUSED_TIME_FAILURE]: (state, action) => ({
      ...state,
      isLoading: false,
      isError: true,
    }),
  },
  PausedVideoInitialStates,
);

export const SubmitRatingReducer = handleActions<ISelectedVideoModal, any>(
  {
    [VideoActionTypes.SUBMIT_RATING_REQUEST]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [VideoActionTypes.SUBMIT_RATING_SUCCESS]: (state, action) => ({
      ...state,
      videoData: action.payload,
      isError: false,
      isLoading: false,
    }),
    [VideoActionTypes.SUBMIT_RATING_FAILURE]: (state, action) => ({
      ...state,
      isLoading: false,
      isError: true,
    }),
  },
  SubmitRatingInitialStates,
);

