import { createLogic } from "redux-logic";
import { ApiHelper } from "../helper/ApiHelper";
import { ApiRoutes, AppRoutes } from "../config";
import { toast } from "react-toastify";
import { getAttendanceFailed, getAttendanceSuccess, hideLoader, showLoader } from "../action/index";
import {
  CheckInActionTypes,
  checkInSubmitSuccess,
  checkInSubmitFailed,
  checkInUsingDateSuccess,
  checkInUsingDateFailed,
  getMotivationFailed, 
  getMotivationSuccess, 
  getTopVideosSuccess,
  getTopVideosFailed,
  redirectTo,
} from "../action";
import { LaptopWindows } from "@material-ui/icons";

let toastId: any = null;

// const checkInSubmitLogic = createLogic({
//   type: CheckInActionTypes.CHECK_IN_SUBMIT_REQUEST,
//   async process(data, dispatch: any, done) {
//     const action: any = data.action;
//     dispatch(showLoader());
//     const response = await new ApiHelper().FetchFromServer(
//       ApiRoutes.CHECK_IN_DATA.service,
//       ApiRoutes.CHECK_IN_DATA.url,
//       ApiRoutes.CHECK_IN_DATA.method,
//       ApiRoutes.CHECK_IN_DATA.authenticate,
//       undefined,
//       action.payload
//     );

//     if (response && !response.isError) {
//       console.log("RESPONSE", response);

//       const { data } = response.data;
//       console.log(response);
//       dispatch(
//         checkInSubmitSuccess({
//           checkInData: data,
//         })
//       );
//       dispatch(hideLoader());
//       done();
//     } else {
//       if (!toast.isActive(toastId)) {
//         toastId = toast.error(response.messages[0]);
//       }

//       dispatch(
//         checkInSubmitFailed({
//           error: response.messages[0],
//           isError: true,
//         })
//       );
//       dispatch(redirectTo({ path: AppRoutes.HOME }));
//       done();
//     }
//   },
// });

const checkInSubmitLogic = createLogic({
  type: CheckInActionTypes.CHECK_IN_SUBMIT_REQUEST,
  async process(data, dispatch: any, done) {
    const action: any = data.action;
    console.log("actionnnnnn checkIn", action)
    dispatch(showLoader());
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.CHECK_IN_DATA.service,
      ApiRoutes.CHECK_IN_DATA.url,
      ApiRoutes.CHECK_IN_DATA.method,
      ApiRoutes.CHECK_IN_DATA.authenticate,
      undefined,
      action.payload
    );
    console.log("responseeeeee", response);
    if (response && !response.isError) {
      if (!toast.isActive(toastId) && !action.payload.courseStarted) {
        toastId = toast.success(response.messages[0]);
      }
      const { data } = response.data;
      console.log(response);
      dispatch(
        checkInSubmitSuccess({
          checkInData: data,
        })
      );
      dispatch(hideLoader());
      dispatch(redirectTo({ path: AppRoutes.DAILY_CHECK_IN }));
      done();
      
    } else {
      if (!toast.isActive(toastId)) {
        toastId = toast.error(response.messages[0]);
      }

      dispatch(
        checkInSubmitFailed({
          error: response.messages[0],
          isError: true,
        })
      );
      done();
    }
  },
});

const checkInUsingDateLogic = createLogic({
  type: CheckInActionTypes.CHECK_IN_USING_DATE_REQUEST,
  async process(data, dispatch: any, done) {
    const action: any = data.action;
    console.log("actionnnnnn checkIn", action)  
    dispatch(showLoader());
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_SELECTED_DATE_CHECK_IN_DATA.service,
      ApiRoutes.GET_SELECTED_DATE_CHECK_IN_DATA.url,
      ApiRoutes.GET_SELECTED_DATE_CHECK_IN_DATA.method,
      ApiRoutes.GET_SELECTED_DATE_CHECK_IN_DATA.authenticate,
      undefined,
      action.payload
    );
    console.log("responseeeeee", response);
    if (response && !response.isError) {
      const { data } = response.data;
      console.log("responseeeeee", response);
      dispatch(
        checkInUsingDateSuccess({
          checkInData: data,
        })
      );
      dispatch(hideLoader());
      done();
      
    } else {
      if (!toast.isActive(toastId)) {
        toastId = toast.error(response.messages[0]);
      }

      dispatch(
        checkInUsingDateFailed({
          error: response.messages[0],
          isError: true,
        })
      );
      done();
    }
  },
});

const getCheckinDateLogic = createLogic({
  type: CheckInActionTypes.GET_CHECK_IN_DATE_REQUEST,
  async process(data, dispatch: any, done) {
    const action: any = data.action;
    console.log("actionnnnnn checkIn", action)  
    dispatch(showLoader());
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_CHECKIN_DATE.service,
      ApiRoutes.GET_CHECKIN_DATE.url,
      ApiRoutes.GET_CHECKIN_DATE.method,
      ApiRoutes.GET_CHECKIN_DATE.authenticate,
      undefined,
      action.payload
    );
    console.log("responseeeeee", response);
    if (response && !response.isError) {
      const { data } = response.data;
      console.log("responseeeeee", response);
      dispatch(
        checkInUsingDateSuccess({
          checkInData: data,
        })
      );
      dispatch(hideLoader());
      done();
      
    } else {
      dispatch(
        checkInUsingDateFailed({
          error: response.messages[0],
          isError: true,
        })
      );
      dispatch(hideLoader());
      done();
    }
  },
});

const getMotivationDataLogic = createLogic({
  type: CheckInActionTypes.GET_MOTIVATION_REQUEST,
  async process(data, dispatch: any, done) {
    const action: any = data.action;
    console.log("actionnnnnn Motivation", action)  
    dispatch(showLoader());
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_MOTIVATION.service,
      ApiRoutes.GET_MOTIVATION.url,
      ApiRoutes.GET_MOTIVATION.method,
      ApiRoutes.GET_MOTIVATION.authenticate,
      undefined,
      action.payload
    );
    console.log("responseeeeee", response);
    if (response && !response.isError) {
      const { data } = response.data;
      console.log("responseeeeee", response);
      dispatch(
        getMotivationSuccess({
          motivationData: data,
        })
      );
      dispatch(hideLoader());
      done();
      
    } else {
      dispatch(
        getMotivationFailed({
          error: response.messages[0],
          isError: true,
        })
      );
      dispatch(hideLoader());
      done();
    }
  },
});

const getAttendanceLogic = createLogic({
  type: CheckInActionTypes.GET_ATTENDANCE_REQUEST,
  async process(data, dispatch: any, done) {
    const action: any = data.action;
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_ATTENDANCE.service,
      ApiRoutes.GET_ATTENDANCE.url,
      ApiRoutes.GET_ATTENDANCE.method,
      ApiRoutes.GET_ATTENDANCE.authenticate,
      undefined,
      action.payload,
    );

    if (response && !response.isError) {
      // const {
      //   data, readCount, totalCount
      // } = response.data;
      dispatch(
        getAttendanceSuccess(
          response.data
        ),
      );
      done();
    } else {
      dispatch(
        getAttendanceFailed({
          error: response.messages[0],
          isError: true,
        }),
      );
      done();
    }
  },
});

const getTopVideosLogic = createLogic({
  type: CheckInActionTypes.GET_TOP_VIDEOS_REQUEST,
  async process(data, dispatch: any, done) {
    const action: any = data.action;
    console.log("actionnnnnn topvideos", action)  
    dispatch(showLoader());
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_TOP_VIDEOS.service,
      ApiRoutes.GET_TOP_VIDEOS.url,
      ApiRoutes.GET_TOP_VIDEOS.method,
      ApiRoutes.GET_TOP_VIDEOS.authenticate,
      undefined,
      action.payload
    );
    console.log("responseeeeee video5", response);
    if (response && !response.isError) {
      const { data } = response.data;
      console.log("responseeeeee v", response);
      dispatch(
        getTopVideosSuccess({
          displayVideos: data,
        })
      );
      dispatch(hideLoader());
      done();
      
    } else {
      dispatch(
        getTopVideosFailed({
          error: response.messages[0],
          isError: true,
        })
      );
      dispatch(hideLoader());
      done();
    }
  },
});

export const CheckInLogic = [checkInSubmitLogic, checkInUsingDateLogic, getCheckinDateLogic, getMotivationDataLogic, getTopVideosLogic, getAttendanceLogic];
