import { createAction } from "redux-actions";

export enum ConfigActionTypes {
  CONFIGURATION_INFO_REQUEST = "Request for Configurations",
  CONFIGURATION_INFO_SUCCESS = "Fetch Configurations successfully",
  CONFIGURATION_INFO_FAILURE = "Failed to load Configurations list",
}

// get profile info
export const getConfigRequest = createAction(
    ConfigActionTypes.CONFIGURATION_INFO_REQUEST
);
export const getConfigSuccess = createAction(
    ConfigActionTypes.CONFIGURATION_INFO_SUCCESS
);
export const getConfigFailed = createAction(
    ConfigActionTypes.CONFIGURATION_INFO_FAILURE
);