import { createAction } from 'redux-actions';

export enum QuizHistoryActionTypes {
  QUIZ_HISTORY_REQUEST = 'Request for quiz history detail',
  QUIZ_HISTORY_SUCCESS = 'Fetch quiz history details successfully',
  QUIZ_HISTORY_FAILURE = 'Failed to load quiz history',
  GET_AVERAGE_SCORE_REQUEST = 'Request for average quiz score',
  GET_AVERAGE_SCORE_SUCCESS = 'Fetch average quiz score details successfully',
  GET_AVERAGE_SCORE_FAILURE = 'Failed to load average quiz score',
  
}



// Fetch course details
export const quizHistoryRequest = createAction(
    QuizHistoryActionTypes.QUIZ_HISTORY_REQUEST,
);
export const quizHistorySuccess = createAction(
    QuizHistoryActionTypes.QUIZ_HISTORY_SUCCESS,
);
export const quizHistoryFailed = createAction(
    QuizHistoryActionTypes.QUIZ_HISTORY_FAILURE,
);
export const getAverageScoreRequest = createAction(
    QuizHistoryActionTypes.GET_AVERAGE_SCORE_REQUEST,
);
export const getAverageScoreSuccess = createAction(
    QuizHistoryActionTypes.GET_AVERAGE_SCORE_SUCCESS,
);
export const getAverageScoreFailed = createAction(
    QuizHistoryActionTypes.GET_AVERAGE_SCORE_FAILURE,
);