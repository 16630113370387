import { createAction } from 'redux-actions';

export enum VerifiedActionType {
  VERIFIED_TOKEN_REQUEST = "Get VERIFIED Request!",
  VERIFIED_TOKEN_SUCCESS = "VERIFIED data fetch Succesfully!",
  VERIFIED_TOKEN_FAILED = "VERIFIED request failed!",
}

/**
 * Verified Token (ORGANIZATION PORFILE) 
 */
export const verifiedTokenRequest = createAction(
  VerifiedActionType.VERIFIED_TOKEN_REQUEST,
);
export const verifiedTokenSuccess = createAction(
  VerifiedActionType.VERIFIED_TOKEN_SUCCESS,
);
export const verifiedTokenFailed = createAction(
  VerifiedActionType.VERIFIED_TOKEN_FAILED,
);
