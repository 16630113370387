import { createLogic } from 'redux-logic';
import { ApiRoutes } from '../config';
import { ApiHelper } from '../helper/ApiHelper';
import {
  ModuleActionTypes,
  moduleDetailSuccess,
  moduleDetailFailed,
  getSelectedModuleSuccess,
  getSelectedModuleFailed,
  updateModuleProgressSuccess,
  updateModuleProgressFailed,
  getKPIModuleSuccess,
  getKPIModuleFailed,
  getKPIUserModuleFailed,
  getKPIUserModuleSuccess,
} from '../action/Module';
import { IRootState } from '../interfaces';
import { logger } from '../helper/Logger';
import { redirectTo, showLoader, hideLoader } from '../action';
const getModuleDetailLogic = createLogic({
  type: ModuleActionTypes.MODULE_DETAIL_REQUEST,
  async process(data, dispatch: any, done) {

    const action: any = data.action;
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_MODULE_DETAIL.service,
      ApiRoutes.GET_MODULE_DETAIL.url,
      ApiRoutes.GET_MODULE_DETAIL.method,
      ApiRoutes.GET_MODULE_DETAIL.authenticate,
      action.payload,
      undefined,
    );
    if (response && !response.isError) {
      const {
        data,
      } = response.data;
      dispatch(
        moduleDetailSuccess({
          moduleData:
            data,
        }),
      );
      done();
    } else {
      dispatch(
        moduleDetailFailed({
          error: response.messages[0],
          isError: true,
        }),
      );
      done();
    }
  },
});

//    



const getSelectedModuleLogic = createLogic({
  type: ModuleActionTypes.SELECTED_MODULE_DETAIL_REQUEST,
  async process(data, dispatch: any, done) {
    const action: any = data.action;
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_SELECTED_MODULE.service,
      ApiRoutes.GET_SELECTED_MODULE.url,
      ApiRoutes.GET_SELECTED_MODULE.method,
      ApiRoutes.GET_SELECTED_MODULE.authenticate,
      action.payload.data,
      undefined,
    );

    if (response && !response.isError) {
      // const {
      //   data, readCount, totalCount
      // } = response.data;
      dispatch(
        getSelectedModuleSuccess(
          response.data
        ),
      );
      done();
    } else {
      dispatch(
        getSelectedModuleFailed({
          error: response.messages[0],
          isError: true,
        }),
      );
      done();
    }
  },
});



const updateModuleProgressLogic = createLogic({
  type: ModuleActionTypes.SELECTED_MODULE_PROGRESS_UPDATE_REQUEST,
  async process(data, dispatch: any, done) {
    const action: any = data.action;
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_MODULE_PROGRESS.service,
      ApiRoutes.UPDATE_MODULE_PROGRESS.url,
      ApiRoutes.UPDATE_MODULE_PROGRESS.method,
      ApiRoutes.UPDATE_MODULE_PROGRESS.authenticate,
      undefined,
      action.payload,
    );

    if (response && !response.isError) {
      // const {
      //   data, readCount, totalCount
      // } = response.data;
      dispatch(
        updateModuleProgressSuccess(
          response.data
        ),
      );
      done();
    } else {
      dispatch(
        updateModuleProgressFailed({
          error: response.messages[0],
          isError: true,
        }),
      );
      done();
    }
  },
});

const GetKPIModuleLogic = createLogic({
  type: ModuleActionTypes.GET_KPI_MODULE_REQUEST,
  async process(data, dispatch: any, done) {
    const action: any = data.action;
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_MODULE_COUNT.service,
      ApiRoutes.GET_MODULE_COUNT.url,
      ApiRoutes.GET_MODULE_COUNT.method,
      ApiRoutes.GET_MODULE_COUNT.authenticate,
      undefined,
      action.payload,
    );

    if (response && !response.isError) {
      // const {
      //   data, readCount, totalCount
      // } = response.data;
      dispatch(
        getKPIModuleSuccess(
          response.data
        ),
      );
      done();
    } else {
      dispatch(
        getKPIModuleFailed({
          error: response.messages[0],
          isError: true,
        }),
      );
      done();
    }
  },
});


const GetKPIUserModuleLogic = createLogic({
  type: ModuleActionTypes.GET_KPI_USER_MODULE_REQUEST,
  async process(data, dispatch: any, done) {
    const action: any = data.action;
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_USER_MODULE_COUNT.service,
      ApiRoutes.GET_USER_MODULE_COUNT.url,
      ApiRoutes.GET_USER_MODULE_COUNT.method,
      ApiRoutes.GET_USER_MODULE_COUNT.authenticate,
      undefined,
      action.payload,
    );

    if (response && !response.isError) {
      // const {
      //   data, readCount, totalCount
      // } = response.data;
      dispatch(
        getKPIUserModuleSuccess(
          response.data
        ),
      );
      done();
    } else {
      dispatch(
        getKPIUserModuleFailed({
          error: response.messages[0],
          isError: true,
        }),
      );
      done();
    }
  },
});



export const ModuleLogics = [
  getModuleDetailLogic,
  getSelectedModuleLogic,
  updateModuleProgressLogic,
  GetKPIModuleLogic,
  GetKPIUserModuleLogic,
];
