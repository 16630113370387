import { createAction } from "redux-actions";

export enum CheckInActionTypes {
  CHECK_IN_SUBMIT_REQUEST = "Check In Submit Request",
  CHECK_IN_SUBMIT_SUCCESS = "Check In Submit Request successfully",
  CHECK_IN_SUBMIT_FAILURE = "Check In Submit Request Failed!",
  CHECK_IN_USING_DATE_REQUEST = "Check In Using Date Request",
  CHECK_IN_USING_DATE_SUCCESS = "Check In Using Date Request successfully",
  CHECK_IN_USING_DATE_FAILURE = "Check In Using Date Failed!",  
  GET_CHECK_IN_DATE_REQUEST = "Get Check In Date Request",
  GET_CHECK_IN_DATE_SUCCESS = "Get Check In Date Request successfully",
  GET_CHECK_IN_DATE_FAILURE = "Get Check In Date Failed!", 
  GET_MOTIVATION_REQUEST = "Get Motivation Request",
  GET_MOTIVATION_SUCCESS = "Get Motivation successfully",
  GET_MOTIVATION_FAILURE = "Get Motivation Failed!",
  GET_TOP_VIDEOS_REQUEST = "Top Videos Request",
  GET_TOP_VIDEOS_SUCCESS = "Top Videos successfully",
  GET_TOP_VIDEOS_FAILURE = "Top Videos Failed!",
  GET_ATTENDANCE_REQUEST = "Attendance Percentage Request",
  GET_ATTENDANCE_SUCCESS = "Attendance Percentage successfully",
  GET_ATTENDANCE_FAILURE = "Attendance Percentage Failed!",
}

// Fetch course details

export const checkInSubmitRequest = createAction(
  CheckInActionTypes.CHECK_IN_SUBMIT_REQUEST
);
export const checkInSubmitSuccess = createAction(
  CheckInActionTypes.CHECK_IN_SUBMIT_SUCCESS
);
export const checkInSubmitFailed = createAction(
  CheckInActionTypes.CHECK_IN_SUBMIT_FAILURE
);
export const checkInUsingDateRequest = createAction(
  CheckInActionTypes.CHECK_IN_USING_DATE_REQUEST
);
export const checkInUsingDateSuccess = createAction(
  CheckInActionTypes.CHECK_IN_USING_DATE_SUCCESS
);
export const checkInUsingDateFailed = createAction(
  CheckInActionTypes.CHECK_IN_USING_DATE_FAILURE
);
export const getCheckinDateRequest = createAction(
  CheckInActionTypes.GET_CHECK_IN_DATE_REQUEST
);
export const getCheckinDateSuccess = createAction(
  CheckInActionTypes.GET_CHECK_IN_DATE_SUCCESS
);
export const getCheckinDateFailed = createAction(
  CheckInActionTypes.GET_CHECK_IN_DATE_FAILURE
);
export const getMotivationRequest = createAction(
  CheckInActionTypes.GET_MOTIVATION_REQUEST
);
export const getMotivationSuccess = createAction(
  CheckInActionTypes.GET_MOTIVATION_SUCCESS
);
export const getMotivationFailed = createAction(
  CheckInActionTypes.GET_MOTIVATION_FAILURE
);
export const getTopVideosRequest = createAction(
  CheckInActionTypes.GET_TOP_VIDEOS_REQUEST
);
export const getTopVideosSuccess = createAction(
  CheckInActionTypes.GET_TOP_VIDEOS_SUCCESS
);
export const getTopVideosFailed = createAction(
  CheckInActionTypes.GET_TOP_VIDEOS_FAILURE
);
export const getAttendanceRequest = createAction(
  CheckInActionTypes.GET_ATTENDANCE_REQUEST
);
export const getAttendanceSuccess = createAction(
  CheckInActionTypes.GET_ATTENDANCE_SUCCESS
);
export const getAttendanceFailed = createAction(
  CheckInActionTypes.GET_ATTENDANCE_FAILURE
);