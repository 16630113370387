import { createLogic } from "redux-logic";
import { toast } from "react-toastify";
import { ApiRoutes } from "../config";
import { ApiHelper } from "../helper/ApiHelper";
import {
  ConfigActionTypes,
  getConfigRequest,
  getConfigSuccess,
  getConfigFailed,
} from "../action";
import { IRootState } from "../interfaces";

let toastId: any = null;
// create logic here
const configInfoLogic = createLogic({
  type: ConfigActionTypes.CONFIGURATION_INFO_REQUEST,
  async process(data, dispatch: any, done) {
    const action: any = data.action;
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_CONFIG_INFO.service,
      ApiRoutes.GET_CONFIG_INFO.url,
      ApiRoutes.GET_CONFIG_INFO.method,
      ApiRoutes.GET_CONFIG_INFO.authenticate,
      undefined,
      action.payload
    );
    if (response && !response.isError && response.data) {
      //const { data, courseId } = response.data;
      const { data } = response.data;
      dispatch(
        getConfigSuccess({
          //configInfo: { ...data, profileCompleted },
          configInfo: { ...data},
        })
      );
      //dispatch(getConfigRequest());
      done();
    } else {
      toast.dismiss();
      if (!toast.isActive(toastId)) {
        toastId = toast.error(response.messages[0]);
      }
      dispatch(
        getConfigFailed({
          error: response.messages[0],
          isError: true,
        })
      );
      done();
    }
  },
});

export const ConfigInfoLogics = [
  configInfoLogic,
];
