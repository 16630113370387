import { Logic, createLogic } from "redux-logic";
import { push } from "react-router-redux";
import { LoginLogics } from "./Auth";
import { ProfileInfoLogics } from "./Profile";
import { ChangePasswordLogics } from "./ChangePassword";
import { VideoLogics } from "./Video";
import { HomePageLogic } from "./HomePage";
import { VerificationLogic } from "./VerifiedToken";
import { QuizLogic } from "./Quiz";
import { CheckInLogic } from "./checkIn";
import { ReviewQuizLogic } from "./Review-Quiz";
import { ModuleLogics } from "./Module";
import { HelpFaqLogic } from "./Help_faq";
import { ConfigInfoLogics } from "./Configuration";

export const redirectToLogic = createLogic({
  type: "REDIRET_TO",
  async process(data, dispatch: any, done) {
    const action: any = data.action;
    dispatch(
      push({ pathname: action.payload.path, state: action.payload.state })
    );
    done();
  },
});
// @ts-ignore
export const AllLogics: Logic[] = [
  ...LoginLogics,
  ...CheckInLogic,
  ...ProfileInfoLogics,
  ...ConfigInfoLogics,
  ...ChangePasswordLogics,
  ...VideoLogics,
  ...HomePageLogic,
  ...VerificationLogic,
  ...ModuleLogics,
  ...QuizLogic,
  ...HelpFaqLogic,
  ...ReviewQuizLogic,
  redirectToLogic,
];
