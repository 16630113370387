import { handleActions } from "redux-actions";
import { CheckInActionTypes } from "../action";
import { MotivationModal } from "../interfaces";
import { MotivationInitialStates } from "../states";

export const motivationReducer = handleActions<MotivationModal, any>(
  {
    [CheckInActionTypes.GET_MOTIVATION_REQUEST]:
      (state = MotivationInitialStates, action
      ): MotivationModal => ({
      ...state,
      isLoading: true,
    }),
    [CheckInActionTypes.GET_MOTIVATION_SUCCESS]: (state=MotivationInitialStates, action) :MotivationModal=> ({
      ...state,
      motivationData: action.payload.motivationData,
      isError: false,
      isLoading: false,
    }),
    [CheckInActionTypes.GET_MOTIVATION_FAILURE]: (state=MotivationInitialStates, action):MotivationModal => ({
      ...state,
      isLoading: false,
      isError: true,
    }),
  },
  MotivationInitialStates,
);



