import { createLogic } from 'redux-logic';
import { toast } from 'react-toastify';
import {
  showLoader,
  hideLoader,
  HomePageActionTypes,
  viewHomePageSuccess,
  viewHomePageFailed,
} from '../action';
import { ApiHelper } from '../helper/ApiHelper';
import { ApiRoutes } from '../config';


let toastId: any = null;

const viewHomePage = createLogic({
  type: HomePageActionTypes.HOMEPAGE_DETAILS_REQUEST,
  async process(data, dispatch: any, done) {
    const action: any = data.action;
    dispatch(showLoader());
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.HOMEPAGE_DETAILS.service,
      ApiRoutes.HOMEPAGE_DETAILS.url,
      ApiRoutes.HOMEPAGE_DETAILS.method,
      ApiRoutes.HOMEPAGE_DETAILS.authenticate,
      action.payload,
      undefined,
    );
    if (response && !response.isError) {
      const { data } = response.data;


      dispatch(hideLoader());
      dispatch(
        viewHomePageSuccess({
          homePageInfo: data,
        }),
      );
      done();
    } else {
      if (!toast.isActive(toastId)) {
        toastId = toast.error(response.messages[0]);
      }
      dispatch(hideLoader());
      dispatch(viewHomePageFailed());
      done();
    }
  },
});


export const HomePageLogic = [
  viewHomePage,
];
