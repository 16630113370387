import { createAction } from 'redux-actions';

export enum HomePageActionTypes {
  HOMEPAGE_DETAILS_REQUEST = 'Request for HomePage VIEW',
  HOMEPAGE_DETAILS_SUCCESS = 'HomePage VIEW successfully',
  HOMEPAGE_DETAILS_FAILURE = 'HomePage view failed',
}

// HomePage Details
export const viewHomePageRequest = createAction(
  HomePageActionTypes.HOMEPAGE_DETAILS_REQUEST,
);
export const viewHomePageSuccess = createAction(
  HomePageActionTypes.HOMEPAGE_DETAILS_SUCCESS,
);
export const viewHomePageFailed = createAction(
  HomePageActionTypes.HOMEPAGE_DETAILS_FAILURE,
);