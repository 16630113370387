import { createAction } from "redux-actions";

export enum ProfileActionTypes {
  PROFILE_INFO_REQUEST = "Request for PROFILEs",
  PROFILE_INFO_SUCCESS = "Fetch PROFILE successfully",
  PROFILE_INFO_FAILURE = "Failed to load occupation list",
  UPDATE_PROFILE_INFO_REQUEST = "Request for update PROFILEs",
  UPDATE_PROFILE_INFO_SUCCESS = "Fetch PROFILE updated successfully",
  UPDATE_PROFILE_INFO_FAILURE = "Profile updation failed",
  UPDATE_PROFILE_MODEL_POPUP_REQUEST = "Request for update profile popup",
  UPDATE_PROFILE_MODEL_POPUP_SUCCESS = "Fetch PROFILE popup updated successfully",
  UPDATE_PROFILE_MODEL_POPUP_FAILURE = "Profile popup updation failed",
  UPDATE_IMAGE_REQUEST = "Request for update image",
  DELETE_IMAGE_REQUEST = "Request for DELETE image",
}

// get profile info
export const getProfileRequest = createAction(
  ProfileActionTypes.PROFILE_INFO_REQUEST
);
export const getProfileSuccess = createAction(
  ProfileActionTypes.PROFILE_INFO_SUCCESS
);
export const getProfileFailed = createAction(
  ProfileActionTypes.PROFILE_INFO_FAILURE
);

// Update profile info
export const updateProfileRequest = createAction(
  ProfileActionTypes.UPDATE_PROFILE_INFO_REQUEST
);
export const updateProfileSuccess = createAction(
  ProfileActionTypes.UPDATE_PROFILE_INFO_SUCCESS
);
export const updateProfileFailed = createAction(
  ProfileActionTypes.UPDATE_PROFILE_INFO_FAILURE
);

// Update profile Popup info
export const UPDATE_PROFILE_MODEL_POPUP_REQUEST = createAction(
  ProfileActionTypes.UPDATE_PROFILE_INFO_REQUEST
);
export const UPDATE_PROFILE_MODEL_POPUP_SUCCESS = createAction(
  ProfileActionTypes.UPDATE_PROFILE_INFO_SUCCESS
);
export const UPDATE_PROFILE_MODEL_POPUP_FAILURE = createAction(
  ProfileActionTypes.UPDATE_PROFILE_INFO_FAILURE
);

// Update profile info
export const updateImageRequest = createAction(
  ProfileActionTypes.UPDATE_IMAGE_REQUEST
);

// Delete profile info
export const deleteImageRequest = createAction(
  ProfileActionTypes.DELETE_IMAGE_REQUEST
);
