import { createAction } from 'redux-actions';

export enum ModuleActionTypes {
  MODULE_DETAIL_REQUEST = 'Request for module detail',
  MODULE_DETAIL_SUCCESS = 'Fetch module details successfully',
  MODULE_DETAIL_FAILURE = 'Failed to load module detail',
  SELECTED_MODULE_DETAIL_REQUEST = 'Request for get selected module',
  SELECTED_MODULE_DETAIL_SUCCESS = 'Fetch selected module successfully.',
  SELECTED_MODULE_DETAIL_FAILURE = 'Failed to load selected module.',
  SELECTED_MODULE_PROGRESS_UPDATE_REQUEST = 'Request for update module progress',
  SELECTED_MODULE_PROGRESS_UPDATE_SUCCESS = 'Update progress for module succesfully.',
  SELECTED_MODULE_PROGRESS_UPDATE_FAILURE = 'Failed to update module progress.',
  GET_KPI_MODULE_REQUEST = "Module Count Request",
  GET_KPI_MODULE_SUCCESS = "Module Count successfully",
  GET_KPI_MODULE_FAILURE = "Module Count Failed!",
  GET_KPI_USER_MODULE_REQUEST = "User Module Count Request",
  GET_KPI_USER_MODULE_SUCCESS = "User Module Count successfully",
  GET_KPI_USER_MODULE_FAILURE = "User Module Count Failed!",
}

// Fetch course details
export const moduleDetailRequest = createAction(
  ModuleActionTypes.MODULE_DETAIL_REQUEST,
);
export const moduleDetailSuccess = createAction(
  ModuleActionTypes.MODULE_DETAIL_SUCCESS,
);
export const moduleDetailFailed = createAction(
  ModuleActionTypes.MODULE_DETAIL_FAILURE,
);


// Fetch selected video
export const getSelectedModuleRequest = createAction(
  ModuleActionTypes.SELECTED_MODULE_DETAIL_REQUEST,
);
export const getSelectedModuleSuccess = createAction(
  ModuleActionTypes.SELECTED_MODULE_DETAIL_SUCCESS,
);
export const getSelectedModuleFailed = createAction(
  ModuleActionTypes.SELECTED_MODULE_DETAIL_FAILURE,
);

// Fetch selected video
export const updateModuleProgressRequest = createAction(
  ModuleActionTypes.SELECTED_MODULE_PROGRESS_UPDATE_REQUEST,
);
export const updateModuleProgressSuccess = createAction(
  ModuleActionTypes.SELECTED_MODULE_PROGRESS_UPDATE_SUCCESS,
);
export const updateModuleProgressFailed = createAction(
  ModuleActionTypes.SELECTED_MODULE_PROGRESS_UPDATE_FAILURE,
);


export const getKPIModuleRequest = createAction(
  ModuleActionTypes.GET_KPI_MODULE_REQUEST
);
export const getKPIModuleSuccess = createAction(
  ModuleActionTypes.GET_KPI_MODULE_SUCCESS
);
export const getKPIModuleFailed = createAction(
  ModuleActionTypes.GET_KPI_MODULE_FAILURE
);
export const getKPIUserModuleRequest = createAction(
  ModuleActionTypes.GET_KPI_USER_MODULE_REQUEST
);
export const getKPIUserModuleSuccess = createAction(
  ModuleActionTypes.GET_KPI_USER_MODULE_SUCCESS
);
export const getKPIUserModuleFailed = createAction(
  ModuleActionTypes.GET_KPI_USER_MODULE_FAILURE
);