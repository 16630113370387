import {
  LoginInitialState,
  linkVerificationInitialState,
  ForgotPasswordInitialState,
  ResetPasswordInitialState,
} from '../states';
import { handleActions } from 'redux-actions';
import { ILoginModal, ILinkVerificationModal } from '../interfaces';
import { AuthActionTypes } from '../action';

export const loginReducer = handleActions<ILoginModal, any>(
  {
    [AuthActionTypes.LOGIN_REQUEST]: (
      state = LoginInitialState,
      action,
    ): ILoginModal => ({
      ...state,
      isLoading: true,
      isLoggedIn: false,
      userData: {}, 
    }),
    [AuthActionTypes.LOGIN_SUCCESS]: (
      state = LoginInitialState,
      action,
    ): ILoginModal => ({
      ...state,
      isLoading: false,
      isLoggedIn: true,
      userData: action.payload.userData,
    }),
    [AuthActionTypes.LOGIN_FAILURE]: (
      state = LoginInitialState,
      action,
    ): ILoginModal => ({
      ...state,
      isLoading: false,
      isLoggedIn: false,
      error: action.payload.error,
      attempts: action.payload.attempts,
      userData: {},      
    }),
  },
  LoginInitialState,
);

export const forgotPasswordReducer = handleActions<any, any>(
  {
    [AuthActionTypes.FORGOT_PASSWORD_REQUEST]: (
      state = ForgotPasswordInitialState,
      action,
    ): any => ({
      ...state,
      isLoading: true,
    }),
    [AuthActionTypes.FORGOT_PASSWORD_SUCCESS]: (
      state = ForgotPasswordInitialState,
      action,
    ): any => ({
      ...state,
      isLoading: false,
      isError: false,
    }),
    [AuthActionTypes.FORGOT_PASSWORD_FAILURE]: (
      state = ForgotPasswordInitialState,
      action,
    ): any => ({
      ...state,
      isLoading: false,
      isError: true,
    }),
  },
  ForgotPasswordInitialState,
);

export const linkVerificationReducer = handleActions<any, any>(
  {
    [AuthActionTypes.LINK_VERIFICATION_REQUEST]: (
      state = linkVerificationInitialState,
      action,
    ): ILinkVerificationModal => ({
      ...state,
      isLoading: true,
    }),
    [AuthActionTypes.LINK_VERIFICATION_SUCCESS]: (
      state = linkVerificationInitialState,
      action,
    ): ILinkVerificationModal => ({
      ...state,
      isLoading: true,
      verificationData: action.payload.verificationData,
      isVerified: true,
    }),
    [AuthActionTypes.LINK_VERIFICATION_FAILURE]: (
      state = linkVerificationInitialState,
      action,
    ): ILinkVerificationModal => ({
      ...state,
      isLoading: true,
      isVerified: false,
    }),
  },
  linkVerificationInitialState,
);

export const resetPasswordReducer = handleActions<any, any>(
  {
    [AuthActionTypes.RESET_PASSWORD_REQUEST]: (
      state = ResetPasswordInitialState,
      action,
    ): ILinkVerificationModal => ({
      ...state,
      isLoading: true,
    }),
    [AuthActionTypes.RESET_PASSWORD_SUCCESS]: (
      state = ResetPasswordInitialState,
      action,
    ): ILinkVerificationModal => ({
      ...state,
      isLoading: false,
      isError: false,
    }),
    [AuthActionTypes.RESET_PASSWORD_FAILURE]: (
      state = ResetPasswordInitialState,
      action,
    ): ILinkVerificationModal => ({
      ...state,
      isLoading: false,
      isError: true,
    }),
  },
  ResetPasswordInitialState,
);
