import { handleActions } from "redux-actions";
import { CheckInActionTypes } from "../action";
import { ICheckInModal, ICheckInAttendanceModal } from "../interfaces";
import { CheckInInitialStates, attendanceInitialStates } from "../states";
export const checkInReducer = handleActions<ICheckInModal, any>(
  {
    [CheckInActionTypes.CHECK_IN_USING_DATE_REQUEST]:
      (state = CheckInInitialStates, action
      ): ICheckInModal => ({
      ...state,
      isLoading: true,
      checkInData: action.payload.checkInData,

    }),
    [CheckInActionTypes.CHECK_IN_USING_DATE_SUCCESS]: (state=CheckInInitialStates, action) :ICheckInModal=> ({
      ...state,
      checkInData: action.payload.checkInData,
      isError: false,
      isLoading: false,
    }),
    [CheckInActionTypes.CHECK_IN_USING_DATE_FAILURE]: (state=CheckInInitialStates, action):ICheckInModal => ({
      ...state,
      isLoading: false,
      isError: true,
    }),
    [CheckInActionTypes.GET_CHECK_IN_DATE_REQUEST]:
      (state = CheckInInitialStates, action
      ): ICheckInModal => ({
      ...state,
      isLoading: true,
      checkInData: action.payload.checkInData,

    }),
    [CheckInActionTypes.GET_CHECK_IN_DATE_SUCCESS]: (state=CheckInInitialStates, action) :ICheckInModal=> ({
      ...state,
      checkInData: action.payload.checkInData,
      isError: false,
      isLoading: false,
    }),
    [CheckInActionTypes.GET_CHECK_IN_DATE_FAILURE]: (state=CheckInInitialStates, action):ICheckInModal => ({
      ...state,
      isLoading: false,
      isError: true,
    }),
  },
  CheckInInitialStates,
);

export const attendanceReducer = handleActions<ICheckInAttendanceModal, any>(
  {
    [CheckInActionTypes.GET_ATTENDANCE_REQUEST]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [CheckInActionTypes.GET_ATTENDANCE_SUCCESS]: (state, action) => ({
      ...state,
      attendanceData: action.payload.data,
      isError: false,
      isLoading: false,
    }),
    [CheckInActionTypes.GET_MOTIVATION_FAILURE]: (state, action) => ({
      ...state,
      isLoading: false,
      isError: true,
    }),
  },
  attendanceInitialStates,
);


// export const SelectedDateCheckInReducer = handleActions<ISelectedDateCheckInModal, any>(
//   {
//     [CheckInActionTypes.CHECK_IN_USING_DATE_REQUEST]:
//       (state = CheckInInitialStates, action
//       ): ICheckInModal => ({
//       ...state,
//       isLoading: true,
//       checkInData: action.payload.checkInData,

//     }),
//     [CheckInActionTypes.CHECK_IN_USING_DATE_SUCCESS]: (state=SelectedDateCheckIInitialStates, action) :ICheckInModal=> ({
//       ...state,
//       checkInData: action.payload.checkInData,
//       isError: false,
//       isLoading: false,
//     }),
//     [CheckInActionTypes.CHECK_IN_USING_DATE_FAILURE]: (state=SelectedDateCheckIInitialStates, action):ICheckInModal => ({
//       ...state,
//       isLoading: false,
//       isError: true,
//     }),
//   },
//   SelectedDateCheckIInitialStates,
// );