import { createAction } from 'redux-actions';

export enum QuizActionType {
  QUIZ_REQUEST = "Quiz questions Request!",
  QUIZ_SUCCESS = "Quiz data fetched Succesfully!",
  QUIZ_FAILED = "Quiz request failed!",
  QUIZ_SUBMIT_REQUEST = "Quiz submit answers Request!",
  QUIZ_SUBMIT_SUCCESS = "Submitted answers Successfully!",
  QUIZ_SUBMIT_FAILED = "Submitted request failed!"
}

/**
 * Quiz
 */
export const quizRequest = createAction(
  QuizActionType.QUIZ_REQUEST,
);
export const quizSuccess = createAction(
  QuizActionType.QUIZ_SUCCESS,
);
export const quizFailed = createAction(
  QuizActionType.QUIZ_FAILED,
);
export const quizSubmitRequest = createAction(
  QuizActionType.QUIZ_SUBMIT_REQUEST,
);
export const quizSubmitSuccess = createAction(
  QuizActionType.QUIZ_SUBMIT_SUCCESS,
);
export const quizSubmitFailed = createAction(
  QuizActionType.QUIZ_SUBMIT_FAILED,
);
