import { handleActions } from 'redux-actions';
import { IReviewQuizModel } from '../interfaces/Review-Quiz';
import { ReviewQuizActionType } from '../action/Review-Quiz';
import { ReviewQuizInitialState } from '../states/Review-Quiz';

export const reviewQuizReducer = handleActions<IReviewQuizModel, any>(
  {
    [ReviewQuizActionType.REVIEW_QUIZ_REQUEST]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [ReviewQuizActionType.REVIEW_QUIZ_SUCCESS]: (
      state = ReviewQuizInitialState,
      action,
    ): IReviewQuizModel => ({
      ...state,
      isLoading: false,
      quizData: action.payload.quizData,
    }),
    [ReviewQuizActionType.REVIEW_QUIZ_FAILED]: (state, action) => ({
      ...state,
      isLoading: false,
      isError: true,
    }),
  },
    ReviewQuizInitialState,
)