import { createLogic } from 'redux-logic';
import { ApiHelper } from '../helper/ApiHelper';
import { ApiRoutes, AppRoutes } from '../config';
import { toast } from 'react-toastify';
import { ReviewQuizActionType, reviewQuizSuccess, reviewQuizFailed, redirectTo, showLoader } from '../action';
import { hideLoader } from '../action/index';

let toastId: any = null;

/**
|--------------------------------------------------
| Quiz
|--------------------------------------------------
*/

const reviewQuizLogic = createLogic({

  type: ReviewQuizActionType.REVIEW_QUIZ_REQUEST,
  async process(data, dispatch: any, done) {
    const action: any = data.action
    dispatch(showLoader());
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.REVIEW_QUIZ_DATA.service,
      ApiRoutes.REVIEW_QUIZ_DATA.url,
      ApiRoutes.REVIEW_QUIZ_DATA.method,
      ApiRoutes.REVIEW_QUIZ_DATA.authenticate,
      undefined,
      undefined,
    );
    if (response && !response.isError) {
      const { data } = response.data;
      localStorage.setItem('examId', data[data.length-1]);
      dispatch(
        reviewQuizSuccess({
          quizData: data
        }),
      );
      dispatch(redirectTo({ path: AppRoutes.REVIEW_QUIZ }));
      dispatch(hideLoader());
      done();
    } else {
      if (!toast.isActive(toastId)) {
        toastId = toast.error(response.messages[0]);
      }
      dispatch(
        reviewQuizFailed({
          error: response.messages[0],
          isError: true,
        }),
      );
      dispatch(redirectTo({ path: AppRoutes.HOME }));
      dispatch(hideLoader());
      done();
    }
  },
});

export const ReviewQuizLogic = [
  reviewQuizLogic
]

