import { IApiRoutes } from "../interfaces";

export const ApiRoutes: IApiRoutes = {
  // Login
  USER_LOGIN: {
    service: "/auth",
    url: "/login",
    method: "POST",
    authenticate: false,
  },
  FORGOT_PASSWORD: {
    service: "/auth",
    url: "/forgot-password",
    method: "POST",
    authenticate: false,
  },
  LINK_VERIFICATION: {
    service: "/auth",
    url: "/link-verified",
    method: "GET",
    authenticate: false,
  },
  RESET_PASSWORD: {
    service: "/auth",
    url: "/reset-password",
    method: "PUT",
    authenticate: false,
  },
  USER_CHANGE_PASSWORD: {
    service: "/user",
    url: "/change-password",
    method: "PUT",
    authenticate: true,
  },
  GET_PROFILE_INFO: {
    service: "/user",
    url: "/profile",
    method: "GET",
    authenticate: true,
  },
  UPDATE_PROFILE_INFO: {
    service: "/user",
    url: "/update-profile",
    method: "PUT",
    authenticate: true,
  },
  UPDATE_IMAGE: {
    service: "/user",
    url: "/update-image",
    method: "POST",
    authenticate: true,
  },

  DELETE_IMAGE: {
    service: "/user",
    url: "/delete-image",
    method: "DELETE",
    authenticate: true,
  },

  //Home Page Section
  HOMEPAGE_DETAILS: {
    service: "/home",
    url: "/homePageDetails",
    method: "GET",
    authenticate: true,
  },

  // ==============VIDEO API=========
  // Get video detail
  GET_VIDEO_DETAILS: {
    service: "/video",
    url: "/getVideos",
    method: "GET",
    authenticate: true,
  },

  GET_SELECTED_VIDEO: {
    service: "/video",
    url: "/getSelectedVideos",
    method: "GET",
    authenticate: true,
  },
  PAUSED_TIME_VIDEO: {
    service: "/video",
    url: "/postPausedVideoTime",
    method: "POST",
    authenticate: true,
  },
  SUBMIT_RATING_VIDEO: {
    service: "/video",
    url: "/submitRating",
    method: "POST",
    authenticate: true,
  },

  // =================MODULE API++++++++++++
  GET_MODULE_COUNT: {    
    service: "/trainingModule",
    url: "/getModulesCount",
    method: "GET",
    authenticate: true,
  },
  GET_USER_MODULE_COUNT: {    
    service: "/trainingModule",
    url: "/getModulesUserCount",
    method: "GET",
    authenticate: true,
  },
  //get module
  GET_MODULE_DETAIL: {
    service: "/trainingModule",
    url: "/getModule",
    method: "GET",
    authenticate: true,
  },
  GET_SELECTED_MODULE: {
    service: "/trainingModule",
    url: "/getSelectedModule",
    method: "GET",
    authenticate: true,
  },

  UPDATE_MODULE_PROGRESS: {
    service: "/trainingModule",
    url: "/updateModuleProgress",
    method: "POST",
    authenticate: true,
  },
  QUIZ_DATA: {
    service: "/questions",
    url: "/getQuizData",
    method: "GET",
    authenticate: true,
  },
  SUBMIT_QUIZ: {
    service: "/questions",
    url: "/submitQuizData",
    method: "POST",
    authenticate: true,
  },
  REVIEW_QUIZ_DATA: {
    service: "/questions",
    url: "/getReviewQuizData",
    method: "GET",
    authenticate: true,
  },
  QUIZ_HISTORY: {
    service: "/questions",
    url: "/getQuizHistoryData",
    method: "GET",
    authenticate: true,
  },
  AVG_QUIZ_SCORE: {
    service: "/questions",
    url: "/getQuizAvgScore",
    method: "GET",
    authenticate: true,
  },

  // =================HELP API++++++++++++
  //get module

  

  HELP_FAQ_DATA: {
    service: "/helpFaq",
    url: "/getHelpFaqData",
    method: "GET",
    authenticate: true,
  },

  // =================CHECK IN API++++++++++++

  CHECK_IN_DATA: {
    service: "/checkIn",
    url: "/submitCheckInData",
    method: "POST",
    authenticate: true,
  },

  GET_SELECTED_DATE_CHECK_IN_DATA: {
    service: "/checkIn",
    url: "/historyData",
    method: "POST",
    authenticate: true,
  },
  GET_ATTENDANCE: {
    service: "/checkIn",
    url: "/attendancePercentage",
    method: "GET",
    authenticate: true,
  },
  GET_CHECKIN_DATE: {
    service: "/checkIn",
    url: "/checkinData",
    method: "POST",
    authenticate: true,
  },
  GET_MOTIVATION: {    
    service: "/checkIn",
    url: "/motivationData",
    method: "POST",
    authenticate: true,
  },
  GET_TOP_VIDEOS: {  
    service: "/video",
    url: "/topSuggestedVideos",
    method: "GET",
    authenticate: true,
  },
  GET_CONFIG_INFO: {
    service: "/configuration",
    url: "/getConfiguration",
    method: "POST",
    authenticate: true,
  },
};
