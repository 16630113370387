import { handleActions } from 'redux-actions';
import { IHelpFaqModel } from '../interfaces/help_faq';
import { HelpFaqActionType } from '../action'; 
import { HelpFaqInitialState } from '../states'; 

export const helpFaqReducer = handleActions<IHelpFaqModel, any>(
  {
    [HelpFaqActionType.HELP_FAQ_REQUEST]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [HelpFaqActionType.HELP_FAQ_SUCCESS]: (
      state = HelpFaqInitialState,
      action,
    ): IHelpFaqModel => ({
      ...state,
      isLoading: false,
      helpfaqData: action.payload.helpfaqData,
    }),
    [HelpFaqActionType.HELP_FAQ_FAILED]: (state, action) => ({
      ...state,
      isLoading: false,
      isError: true,
    }),
  },
  HelpFaqInitialState,
)