import { handleActions } from 'redux-actions';
import { IVerifiedTokenModel } from '../interfaces/VerifiedToken';
import { VerifiedActionType } from '../action/VerifiedToken';
import { VerifiedTokenInitialState } from '../states/VerifiedToken';

export const verifiedTokenReducer = handleActions<IVerifiedTokenModel, any>(
  {
    [VerifiedActionType.VERIFIED_TOKEN_REQUEST]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [VerifiedActionType.VERIFIED_TOKEN_SUCCESS]: (state, action) => ({
      ...state,
      verifiedToken: action.payload.verifiedToken,
      isLoading: false,
    }),
    [VerifiedActionType.VERIFIED_TOKEN_FAILED]: (state, action) => ({
      ...state,
      isLoading: false,
      isError: true,
    }),
  },
  VerifiedTokenInitialState,
)