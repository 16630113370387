import { createLogic } from 'redux-logic';
import { ApiRoutes } from '../config';
import { ApiHelper } from '../helper/ApiHelper';
import {
  VideoActionTypes,
  videoDetailSuccess,
  videoDetailFailed,
  getSelectedVideoSuccess,
  getSelectedVideoFailed,
  pausedTimeSuccess,
  pausedTimeFailed,
  submitRatingSuccess,
  submitRatingFailed
} from '../action/Video';

const getVideoDetailLogic = createLogic({
  type: VideoActionTypes.VIDEO_DETAIL_REQUEST,
  async process(data, dispatch: any, done) {
    const action: any = data.action;
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_VIDEO_DETAILS.service,
      ApiRoutes.GET_VIDEO_DETAILS.url,
      ApiRoutes.GET_VIDEO_DETAILS.method,
      ApiRoutes.GET_VIDEO_DETAILS.authenticate,
      action.payload,
      undefined,
    );
    if (response && !response.isError) {
      const {
        data,
      } = response.data;
      console.log("frefefreferf", data);

      dispatch(
        videoDetailSuccess({
          videoDetailsData:
            data,
        }),
      );
      done();
    } else {
      dispatch(
        videoDetailFailed({
          error: response.messages[0],
          isError: true,
        }),
      );
      done();
    }
  },
});




const getSelectedVideoLogic = createLogic({
  type: VideoActionTypes.SELECTED_VIDEO_DETAIL_REQUEST,
  async process(data, dispatch: any, done) {
    const action: any = data.action;
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_SELECTED_VIDEO.service,
      ApiRoutes.GET_SELECTED_VIDEO.url,
      ApiRoutes.GET_SELECTED_VIDEO.method,
      ApiRoutes.GET_SELECTED_VIDEO.authenticate,
      action.payload,
      undefined,
    );
    if (response && !response.isError) {
      dispatch(
        getSelectedVideoSuccess(
          response.data
        ),
      );
      done();
    } else {
      dispatch(
        getSelectedVideoFailed({
          error: response.messages[0],
          isError: true,
        }),
      );
      done();
    }
  },
});

const pausedTimeVideoLogic = createLogic({
  type: VideoActionTypes.PAUSED_TIME_REQUEST,
  async process(data, dispatch: any, done) {
    const action: any = data.action;
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.PAUSED_TIME_VIDEO.service,
      ApiRoutes.PAUSED_TIME_VIDEO.url,
      ApiRoutes.PAUSED_TIME_VIDEO.method,
      ApiRoutes.PAUSED_TIME_VIDEO.authenticate,
      undefined,
      action.payload,
    );
    if (response && !response.isError) {
      dispatch(
        pausedTimeSuccess(
          response.data
        ),
      );
      done();
    } else {
      dispatch(
        pausedTimeFailed({
          error: response.messages[0],
          isError: true,
        }),
      );
      done();
    }
  },
});

const submitRatingLogic = createLogic({
  type: VideoActionTypes.SUBMIT_RATING_REQUEST,
  async process(data, dispatch: any, done) {
    const action: any = data.action;
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.SUBMIT_RATING_VIDEO.service,
      ApiRoutes.SUBMIT_RATING_VIDEO.url,
      ApiRoutes.SUBMIT_RATING_VIDEO.method,
      ApiRoutes.SUBMIT_RATING_VIDEO.authenticate,
      undefined,
      action.payload,
    );
    if (response && !response.isError) {
      dispatch(
        submitRatingSuccess(
          response.data
        ),
      );
      done();
    } else {
      dispatch(
        submitRatingFailed({
          error: response.messages[0],
          isError: true,
        }),
      );
      done();
    }
  },
});

export const VideoLogics = [
  getVideoDetailLogic,
  getSelectedVideoLogic,
  pausedTimeVideoLogic,
  submitRatingLogic
]
