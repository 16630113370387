import { ILoginModal, ILinkVerificationModal } from '../interfaces';

export const LoginInitialState: ILoginModal = {
  isLoading: true,
  isLoggedIn: false,
};

export const ForgotPasswordInitialState: any = {
  isLoading: true,
  isError: false,
};

export const ResetPasswordInitialState: any = {
  isLoading: true,
  isError: false,
};

export const linkVerificationInitialState: ILinkVerificationModal = {
  isLoading: true,
  isVerified: false,
  verificationData: {
    verifyToken: '',
    email: '',
    id: '',
  },
};
