import { IAppRoutes } from '../interfaces';

export const AppRoutes: IAppRoutes = {
  HOME: '/',
  COURSE: '/course',
  LOGIN: '/login',
  RESET_PASSWORD: '/reset-password',
  DASHBOARD: '/dashboard',
  WELCOME: '/welcome',
  MY_ACCOUNT: '/profile',
  MY_MODULE: "/modules",
  MY_SUB_MODULE: "/modules/sub-module/:moduleId/:subModuleID/:subModuleOrder",
  MY_VIDEOS: '/my-videos',
  MY_VIDEOS_VIEW: '/my-videos/view-video/:id',
  PRETEST: '/course/:courseSlug/pretest',
  NOTFOUND: '/404',
  TERMSCONDITIONS: '/terms-conditions',
  PRIVACYPOLICY: '/privacy-policy',
  LOGIN_GUIDE: '/login-guide',
  QUIZ: '/quiz',
  FLAG_REVIEW: '/quiz',
  START_QUIZ: '/start-quiz',
  REVIEW_QUIZ: '/review-quiz',
  QUIZ_REVIEW_FUNCTIONALITY: '/quiz-review-functionality',
  HELP_FAQ: '/help-faq',
  CHECK_IN: '/check-in',
  QUIZ_HISTORY: "/quiz-history",
  RESOURCES:"/resources",
  DAILY_CHECK_IN:"/daily-check-in",
};
