import { ICheckInModal, ISelectedDateCheckInModal, MotivationModal, TopVideosModal, ICheckInAttendanceModal } from "../interfaces/checkin";

export const CheckInInitialStates: ICheckInModal = {
  checkInData: {},
  isError: false,
  isLoading: false
};
export const attendanceInitialStates: ICheckInAttendanceModal = {
  attendanceData: 0,
  isError: false,
  isLoading: false
};
export const MotivationInitialStates: MotivationModal = {
  motivationData: {},
  isError: false,
  isLoading: false
};
export const TopVideosInitialStates: TopVideosModal = {
  displayVideos: {},
  isError: false,
  isLoading: false
};
export const SelectedDateCheckIInitialStates: ISelectedDateCheckInModal = {
  checkInData: {},
  isError: false,
  isLoading: false
};