import { createLogic } from "redux-logic";
import { ApiHelper } from "../helper/ApiHelper";
import { ApiRoutes, AppRoutes } from "../config";
import { toast } from "react-toastify";
import {
  QuizActionType,
  quizSuccess,
  quizFailed,
  quizSubmitSuccess,
  quizSubmitFailed,
  redirectTo,
  showLoader,
  getAverageScoreSuccess,
  getAverageScoreFailed,
  QuizHistoryActionTypes
} from "../action";
import { hideLoader } from "../action/index";

let toastId: any = null;

/**
|--------------------------------------------------
| Quiz
|--------------------------------------------------
*/

const quizLogic = createLogic({
  type: QuizActionType.QUIZ_REQUEST,
  async process(data, dispatch: any, done) {
    const action: any = data.action;
    dispatch(showLoader());
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.QUIZ_DATA.service,
      ApiRoutes.QUIZ_DATA.url,
      ApiRoutes.QUIZ_DATA.method,
      ApiRoutes.QUIZ_DATA.authenticate,
      undefined,
      undefined
    );
    if (response && !response.isError) {
      const { data } = response.data;
      localStorage.setItem("examId", data[data.length - 1]);
      dispatch(
        quizSuccess({
          quizData: data,
        })
      );
      dispatch(redirectTo({ path: AppRoutes.QUIZ }));
      dispatch(hideLoader());
      done();
    } else {
      if (!toast.isActive(toastId)) {
        toastId = toast.error(response.messages[0]);
      }
      dispatch(
        quizFailed({
          error: response.messages[0],
          isError: true,
        })
      );
      dispatch(redirectTo({ path: AppRoutes.HOME }));
      dispatch(hideLoader());
      done();
    }
  },
});


const avgQuizScoreLogic = createLogic({
  type: QuizHistoryActionTypes.GET_AVERAGE_SCORE_REQUEST,
  async process(data, dispatch: any, done) {
    const action: any = data.action;
    dispatch(showLoader());
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.AVG_QUIZ_SCORE.service,
      ApiRoutes.AVG_QUIZ_SCORE.url,
      ApiRoutes.AVG_QUIZ_SCORE.method,
      ApiRoutes.AVG_QUIZ_SCORE.authenticate,
      undefined,
      action.payload,
    );
    if (response && !response.isError) {
      const { data } = response.data;
      //console.log("response.data", response.data);
      dispatch(
        getAverageScoreSuccess(
          response.data
        )
      );
      dispatch(hideLoader());
      done();
    } else {
      if (!toast.isActive(toastId)) {
        toastId = toast.error(response.messages[0]);
      }
      dispatch(
        getAverageScoreFailed({
          error: response.messages[0],
          isError: true,
        })
      );
      dispatch(redirectTo({ path: AppRoutes.HOME }));
      dispatch(hideLoader());
      done();
    }
  },
});
const quizSubmitLogic = createLogic({
  type: QuizActionType.QUIZ_SUBMIT_REQUEST,
  async process(data, dispatch: any, done) {
    const action: any = data.action;
    dispatch(showLoader());

    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.SUBMIT_QUIZ.service,
      ApiRoutes.SUBMIT_QUIZ.url,
      ApiRoutes.SUBMIT_QUIZ.method,
      ApiRoutes.SUBMIT_QUIZ.authenticate,
      undefined,
      action.payload
    );
    if (response && !response.isError) {
      const { data } = response.data;
      dispatch(
        quizSubmitSuccess({
          quizSummaryData: data,
        })
      );
      //dispatch(redirectTo({ path: AppRoutes.QUIZ }));
      dispatch(hideLoader());
      done();
    } else {
      if (!toast.isActive(toastId)) {
        toastId = toast.error(response.messages[0]);
      }
      localStorage.removeItem("usertoken");
      localStorage.removeItem("firstName");
      localStorage.removeItem("viewAddedIds");
      dispatch(
        quizSubmitFailed({
          error: response.messages[0],
          isError: true,
        })
      );
      dispatch(redirectTo({ path: AppRoutes.HOME }));
      done();
    }
  },
});

export const QuizLogic = [quizLogic, quizSubmitLogic, avgQuizScoreLogic];
