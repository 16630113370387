import { createAction } from 'redux-actions';

export enum HelpFaqActionType {
  HELP_FAQ_REQUEST = "Help Faq Request!",
  HELP_FAQ_SUCCESS = "Help Faq data fetched Succesfully!",
  HELP_FAQ_FAILED = "Help Faq request failed!"
}

/**
 * Help
 */
export const helpFaqRequest = createAction(
    HelpFaqActionType.HELP_FAQ_REQUEST,
);
export const helpFaqSuccess = createAction(
    HelpFaqActionType.HELP_FAQ_SUCCESS,
);
export const helpFaqFailed = createAction(
    HelpFaqActionType.HELP_FAQ_FAILED,
);