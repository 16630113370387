import { createAction } from 'redux-actions';

export enum ReviewQuizActionType {
  REVIEW_QUIZ_REQUEST = "Review Quiz Request!",
  REVIEW_QUIZ_SUCCESS = "Review Quiz data fetched Succesfully!",
  REVIEW_QUIZ_FAILED = "Review Quiz request failed!"
}

/**
 * Quiz
 */
export const reviewQuizRequest = createAction(
  ReviewQuizActionType.REVIEW_QUIZ_REQUEST,
);
export const reviewQuizSuccess = createAction(
  ReviewQuizActionType.REVIEW_QUIZ_SUCCESS,
);
export const reviewQuizFailed = createAction(
  ReviewQuizActionType.REVIEW_QUIZ_FAILED,
);